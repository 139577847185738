
import { Vue, Component } from 'vue-property-decorator';
import { GetCustomersByEmail } from '@/api/GetCustomers';
import { DateTime } from 'luxon';

/**
 * Controls the overall progress state of the sales order creation process.
 */
@Component
export default class SalesOrder extends Vue {

  //----------------------------------------------------------------------------
  // Local Variables
  //----------------------------------------------------------------------------
  
  allowedSteps: string[] = [ 'edit', 'review', 'result' ];
  currentStep: string = 'edit';


  //----------------------------------------------------------------------------
  // Computed Properties
  //----------------------------------------------------------------------------

  /**
   * Flag that detects if the user profile has finished loading.
   * 
   * @todo Improve detection method to handle cases where there are no valid
   * customers options.
   */
  get isProfileLoaded(): boolean {
    return this.$store.state.user.availableCustomers.length > 0;
  }

  /**
   * Flag that determines if the announcement alert should be displayed.
   */
  get showAnnouncementAlert(): boolean {
    
    const startDate = DateTime.fromISO( '2023-12-14' ),
          stopDate = DateTime.fromISO( '2024-01-01' ),
          currentDate = DateTime.now(),
          isValidDate: boolean = ( startDate.startOf( 'day' ) <= currentDate && currentDate <= stopDate.endOf( 'day' ) );

    return isValidDate;
  }


  //----------------------------------------------------------------------------
  // Lifecycle Events
  //----------------------------------------------------------------------------

  async mounted() {
    await this.init();
  }


  //----------------------------------------------------------------------------
  // Methods
  //----------------------------------------------------------------------------

  /**
   * Empty the sales order state and returns to the order form.
   */
  resetOrder() {
    this.$store.commit( 'salesOrder/reset' );
    this.currentStep = 'edit';
    this.scrollToTop();
  }

  /**
   * Change to the sales order form component.
   */
  loadOrderForm() {
    this.currentStep = 'edit';
    this.scrollToTop();
  }

  /**
   * Change to the order confirmation/review component.
   */
  loadOrderReview() {
    this.currentStep = 'review';
    this.scrollToTop();
  }

  /**
   * Change to the submission view component.
   */
  submitOrder() {
    this.currentStep = 'result';
    this.scrollToTop();
  }

  /**
   * Utility method to reset scroll position.
   */
  scrollToTop() {
    window.scrollTo( 0, 0 );
  }

  /**
   * Initialize the data required to display the sales order form.
   */
  private async init(): Promise<void> {
    await this.setAvailableCustomers();
  }

  /**
   * Retrieve the customers the user has access to and updates the user store.
   */
  private async setAvailableCustomers(): Promise<void> {

    // Retrieve customers if they aren't already set on the user profile.
    if ( ! this.$store.state.user.availableCustomers?.length ) {
      let availableCustomers = await GetCustomersByEmail( this.$store.state.user.email );

      if ( availableCustomers.length ) {
        this.$store.commit( 'user/setAvailableCustomers', availableCustomers );
      }
    }
  }
}
