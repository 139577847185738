import { sendApiRequest } from "@/api/AxiosWrapper";
import Customer from '@/model/customer';

/**
 * Retrieve the customers that a user has access to.
 */
export async function GetCustomersByEmail( email: string ): Promise<Customer[]> {

  let customers: Customer[] = [];

  let response = await sendApiRequest("/oms/customer/getbyemail", {
    email: email
  });

  for ( const row of response.data ) {
    
    let customer: Customer = {
      uuid: row.uuid,
      name: row.name,
      number: row.number,
      dynamicsUuid: row.dynamics_uuid,
      allowedDeliveryDays: row.allowed_delivery_days || [],
      availableProducts: []
    }

    customers.push( customer );
  }

  return customers;
}
